<template>
    <div>
        <nav-single></nav-single>
        <comments
            :key="id"
            :pid="id"
        ></comments>
    </div>
</template>

<script>
import NavSingle from '@/components/Nav/NavSingle.vue'
import Comments from "@/components/Comments";
export default {
    inject: ['swimSpots'],
    emits: ['spotFocus', 'fetchForecast'],
    data() {
        return {
            id: this.$route.params.id * 1.0,
        };
    },
    components: {
        NavSingle,
        Comments
    },
}
</script>
