<template>
    <div class="comments__wrapper">
        <div id="disqus_thread"></div>
        <div class="info-container">
            <div class="info-container__wrapper">
                <div class="info-icon">
                    <img src="@/assets/img/chat-text.svg" alt="Leave a comment">
                </div>
                <div class="info-text">
                    Have something to say, want to ask a question from the tribe or is this spot missing information please feel free to drop in a comment.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pid: {
            type: Number,
            required: true
        },
    },
    mounted () {
        const pid = this.pid;

        const d = document, s = d.createElement('script');
        window.disqus_config = function () {
            this.page.url = window.location.href;
            this.page.identifier = pid;
        };
        s.src = 'https://swimireland.disqus.com/embed.js';
        s.setAttribute('data-timestamp', + new Date());
        (d.head || d.body).appendChild(s);
    },
}
</script>
<style>
    .comments__wrapper {
        display: flex;
        justify-content: flex-start;
        padding: 66px 113px;
        grid-gap: 120px;
    }

    .comments__wrapper .info-container {
        flex: 1 1 50%;
    }

    .info-container__wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 2px solid #F3F3F3;
        border-radius: 10px;
        padding: 25px 38px;
        text-align: left;
    }

    .info-container__wrapper > .info-text {
        font-size: 20px;
        line-height: 40px;
        margin-top: 9px;
    }

    .info-container__wrapper > .info-action button {
        padding: 19px 26px 18px 25px;
        border: none;
        border-radius: 8px;
        background: #009FAE;
        color: white;
        font-weight: bold;
        letter-spacing: -0.1px;
        line-height: 23px;
    }

    #disqus_thread {
        width: 50%;
    }

    @media (max-width:801px)  {
        .comments__wrapper {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    @media (max-width:1281px) {
        .comments__wrapper {
            flex-direction: column-reverse;
            grid-gap: 30px;
        }
        #disqus_thread {
            width: 100%;
        }
    }
</style>
